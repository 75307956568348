/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import * as managePageActions from "../../../../../../store/actions/managePageActions";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import * as toastActions from "../../../../../../store/actions/toastActions";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_HorizontalStepper from "../../../../Atoms/Navigation/Stepper/A_HorizontalStepper";
import { httpPost } from "../../../../../../Utils/apis/apis";
import {
  getCRMContactInfo,
  showField,
} from "../../../../../../Utils/Helpers/common";
import ErrorBoundary from "../../../../Molecules/Common/error/ErrorBoundary";
import * as manageEntityHelper from "./M_ManageEntityForm.helper";
import { handleUpdate } from "./handleUpdate";
import { security } from "./M_ManageEntityForm.config";
import { useSelector, useDispatch } from "react-redux";
import {
  handleFileUpload,
  handleFileUploadFileShare,
} from "../../File Upload/M_fileUpload.helper";
import {
  toastType,
  toastSeverity,
} from "../../../../../../Utils/config/toastConfig";
import { message } from "../../../../../../Utils/config/messages";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import {
  fieldType,
  labelName,
  dealConfig,
  envName,
  meetingConsts,
} from "../../../../../../Utils/config/config";
import { timeReplaceFromEpoch } from "../../../../../../Utils/Helpers/dateUtils";
import * as loggedInUserIdActions from "../../../../../../store/actions/loggedInUserIdActions";
import { hasPageFilter } from "../../Renderers/Views/Charts/M_ChartCard.Helper";
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";
import {
  setPreviousEntity,
  clearPreviousEntity,
} from "../../../../../../store/actions/previousEntityAction";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles(() => ({
  loader: {
    textAlign: "center",
  },
}));

const M_ManageEntityForm = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [showFormWarning, setShowFormWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formSavedResponse, setFormSavedResponse] = useState(false);
  const [fileUploadConfig, setFileUploadConfig] = useState({});
  const [submitToStoredProc, setSubmitToStoredProc] = useState("");
  const [duplicateDetection, setDuplicateDetection] = useState("");
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  let crmData = props.crmData ? props.crmData : {};
  let checkData = {
    security,
    ...crmData,
  };
  const [formData, setFormData] = useState(checkData);
  const [editFormData, setEditFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [fields, setFields] = useState([]);
  const [optionSets, setOptionSets] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [submitToStoredProcSource, setSubmitToStoredProcSource] = useState("");
  const [autoPopulateData, setAutoPopulateData] = useState([]);
  const [customCalculation, setCustomCalculation] = useState(false);
  // eslint-disable-next-line react/prop-types
  const pageFilters =
    props.toolbarSelections && props.toolbarSelections.filters;
  let isFormSql =
    props.managePage &&
    props.managePage.data &&
    props.managePage.data.template &&
    props.managePage.data.template.templateType
      ? props.managePage.data.template.templateType
      : "";
  let sqlformData =
    props.managePage && props.managePage.data && props.managePage.data.data
      ? props.managePage.data.data
      : "";

  let { crmId } = useParams();
  let { pageUrl } = useParams();

  let { crmContactUserId } = getCRMContactInfo();
  let recordId = crmId;
  let fileName;
  var filelist = [];
  const user = useSelector((state) => state.oidc.user);
  const { fieldName, snackBarContent } = props;
  const prevEntity = useSelector((state) => state.previousEntity.prevEntity);
  const dispatch = useDispatch();
  if (
    props.entity != null &&
    props.entity.recordId == "" &&
    props.editChart == true
  ) {
    props.entity.recordId = crmId;
  }

  if (props.entity != null && props.entity.recordId == null) {
    props.entity.recordId = crmId;
  }
  const portfolio = useSelector(
    (state) => state.proposalTrackerReducer.filterData.portfolio
  );
  const borrower = useSelector(
    (state) => state.proposalTrackerReducer.filterData.borrower
  );
  useEffect(() => {
    let isNew = false;
    if (!prevEntity || Object.keys(prevEntity).length == 0) {
      isNew = true;
    } else if (
      prevEntity.templateId !== props.entity.templateId ||
      (prevEntity.recordId !== recordId &&
        prevEntity.recordId !== props.entity.recordId)
    ) {
      isNew = true;
    }
    if (isNew) {
      props.getPage(props.entity, recordId, props.templateId);
      dispatch(setPreviousEntity(props.entity));
    }
  }, [props.entity]);

  useEffect(() => {
    let templateJson = undefined;
    if (
      props.managePage &&
      props.managePage.data &&
      props.managePage.data.template
    ) {
      templateJson = props.managePage.data.template.templateJson;
      setFields(props.managePage.data.pageConfig.fields);
      setOptionSets(props.managePage.data.pageConfig.optionSets);
    }
    templateJson =
      templateJson !== "" && templateJson !== undefined
        ? JSON.parse(templateJson)
        : {};
    if (templateJson.submitToStoredProc) {
      setSubmitToStoredProc(templateJson.submitToStoredProc);
    }
    if (templateJson.duplicateDetection) {
      setDuplicateDetection(templateJson.duplicateDetection);
    }
    if (templateJson.submitToStoredProcSource) {
      setSubmitToStoredProcSource(templateJson.submitToStoredProcSource);
    }
    if (templateJson.isCustomCalculation) {
      setCustomCalculation(templateJson.isCustomCalculation);
    }
    const stepsRaw = "stepper" in templateJson ? templateJson.stepper : [];
    if (stepsRaw.length > 0 || !props.managePage.openManagePageForm) {
      let formDataCopy = { ...formData };
      let elementsWithDefaultValues =
        manageEntityHelper.getDefaultValues(stepsRaw);
      elementsWithDefaultValues.forEach((ele) => {
        let elementValue = formDataCopy[ele.id];
        if (!elementValue || (elementValue && elementValue == "")) {
          formDataCopy[ele.id] = ele.defaultValue;
        }
      });
      if (!props.editChart) {
        setFormData(formDataCopy);
      } else {
        if (props.entity && props.entity.hasContainer) {
          if (props.managePage && props.managePage.data) {
            setFormData(props.managePage.data.data);
          }
        } else {
          if (props.entity && props.entity.investmentChecklist) {
            setFormData({ ...props.row });
          } else {
            if (
              props.managePage &&
              props.managePage.data &&
              props.managePage.data.data &&
              !props.edgeId
            ) {
              setFormData(props.managePage.data.data);
            } else {
              setFormData(crmData);
            }
          }
        }
      }

      setAllSteps(stepsRaw);
      //check for show-hide conditon on first load of form
      let visibleSteps = handleShowHide(stepsRaw);
      setSteps(visibleSteps);
    }
  }, [props.managePage]);
  const handleClose = () => {
    localStorage.removeItem("calculativeFieldFocus");
    if (formSavedResponse) {
      handleFinish();
    }
    setShowFormWarning(true);
    setFormSavedResponse(false);
    setConfirmationPopup(false);
  };
  const handleWarningOkButton = () => {
    setShowFormWarning(!showFormWarning);
  };
  const handleWarningCloseButton = () => {
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    props.onClose();
    // props.resetManagePage();
  };
  const handleFinish = () => {
    localStorage.removeItem("calculativeFieldFocus");
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    setShowFormWarning(false); //closing incomplete-form warning.
    props.onClose(); //closing add-entities-list
    // props.resetManagePage();
  };

  const handleFormSaved = async () => {
    setIsLoading(true);
    let newFormData = { ...formData };
    // its just a patch for John Laing.
    try {
      if (
        props &&
        props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.description == "Portfolio Investments"
      ) {
        newFormData.Date = manageEntityHelper.getEcpochDateOfOptionSetMonth(
          newFormData.Date
        );
      }
    } catch (e) {
      console.log(e);
    }
    for (let i in newFormData) {
      if (
        newFormData[i] &&
        newFormData[i].fieldType &&
        newFormData[i].fieldType == fieldType.DOCUMENT
      ) {
        filelist.push(newFormData[i]);
        newFormData[i] = undefined;
      }
    }
    let edge = manageEntityHelper.setEdgeLabelData(newFormData);
    let security = { ...newFormData.security };
    delete newFormData.security;
    let label = "";
    if (props.isIRCSlot) {
      newFormData.ircDate = props.IRCSlotDate;
      newFormData.ircTime = props.IRCTime;
      newFormData.ircEndDate = props.IRCSlotEndDate;
      newFormData.subject = props.meetingSubject;
    }
    if (props.showPopup == true) {
      label =
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.pageUrl
          ? props.entity.parameters.pageUrl
          : "";
    } else {
      label =
        props.entity && props.entity.pageName && props.entity.pageName
          ? props.entity.pageName
          : "";
    }

    if (label === labelName.IRC_SLOTES) {
      newFormData.ircDate = timeReplaceFromEpoch(
        newFormData.ircDate,
        newFormData.ircTime
      );
    } else if (label === labelName.INVESTMENT) {
      let hostName = window.location.hostname;
      if (hostName === envName.VELDCAPITAL) {
        let tempDealConfig = { ...dealConfig };
        const curr = Math.floor(Date.now() / 1000);
        tempDealConfig.statusHistory = tempDealConfig.statusHistory
          .split("=currentDate")
          .join(curr);
        newFormData = { ...newFormData, ...tempDealConfig };
      }
    }
    if (label === labelName.MEETING) {
      newFormData["IsTagged"] = false;
      newFormData["IsCancelled"] = false;
      newFormData["isOutlook"] = false;
      edge = edge.map((item) => {
        if (item.label === meetingConsts.ORGANISER) {
          return {
            ...item,
            guid: [item.guid && item.guid[0] ? item.guid[0] : crmContactUserId],
            "two-way-label": meetingConsts.HAS_EVENT,
          };
        } else if (
          item.label === meetingConsts.ATTENDEDBY ||
          item.label === meetingConsts.HAS_COMPANYATTENDEES
        ) {
          return {
            ...item,
            "two-way-label": meetingConsts.ATTENDED,
          };
        } else {
          return item;
        }
      });
    }
    if ("undefined" in newFormData) {
      delete newFormData["undefined"];
    }
    let convertedFormData = {
      id: null,
      label: label,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
      submitToStoredProc: submitToStoredProc,
      duplicateDetection: duplicateDetection,
      submitToStoredProcSource: submitToStoredProcSource,
    };
    try {
      if (
        manageEntityHelper.getObjectFromJSONString(
          props,
          ".managePage.data.template.templateJson"
        )
      ) {
        let templateJSONString = props.managePage.data.template.templateJson;
        let triggerEmail = manageEntityHelper.getObjectFromJSONString(
          templateJSONString,
          ".triggerEmail"
        );
        triggerEmail != undefined
          ? (triggerEmail["emailCRMIds"] = manageEntityHelper.getIdsFromEdges(
              edge,
              triggerEmail.emailEdgeLabels ? triggerEmail.emailEdgeLabels : []
            ))
          : "";
        convertedFormData["triggerEmail"] = triggerEmail;
        let addRefId = manageEntityHelper.getObjectFromJSONString(
          templateJSONString,
          ".addRefId"
        );
        addRefId != undefined ? (convertedFormData["addRefId"] = addRefId) : "";
      }
    } catch (error) {
      //intentionally write comment
    }

    if (
      props.entity &&
      props.entity.parameters &&
      props.entity.parameters.hasContainer
    ) {
      if (
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.hasContainer &&
        props.entity.parameters.updatePayloadDocumentDb &&
        props.entity.parameters.hasContainer ===
          props.entity.parameters.updatePayloadDocumentDb
      ) {
        await manageEntityHelper.schedulerCustomPayload(
          convertedFormData,
          crmId,
          props.entity
        );
      }
      const response = await httpPost(
        `/GenericDocument/${props.entity.parameters.hasContainer}/insert`,
        convertedFormData.properties
      );
      if ("message" in response) {
        setIsError(response);
        setIsLoading(false);
      } else {
        props.getCurrentPage({ pageUrl, crmId });
        setIsError(false);
      }
    } else {
      let res = await manageEntityHelper.CRMEdgeVertexEngine(
        convertedFormData,
        props,
        crmId,
        pageUrl,
        user
      );
      //This if condition is used for geeting image saved as crmId name (use for ImageCard component)
      if (
        props &&
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.label &&
        props.entity.parameters.label.toLowerCase() == "imagecard"
      ) {
        res.id = crmId;
      }
      fileName = res.id + "/";
      if (filelist && filelist.length > 0) {
        handleFileUpload(
          "",
          res.id,
          user,
          filelist,
          fileUploadConfig,
          fileName,
          "",
          "",
          false,
          !props.refresh,
          false
        );
      }

      if (
        props.isIRCSlot &&
        res.edges &&
        res.edges[0] &&
        res.edges[0].guid[0]
      ) {
        if (res.properties.isApprovedData === undefined) {
          let payloadChecklist = {
            dealId:
              res && res.edges[0] && res.edges[0].guid[0]
                ? res.edges[0].guid[0]
                : "",
            dealWorkFlowState: "OnBoarding",
            dateActive: props.IRCSlotDate,
            ircPurpose: handleOptionSetValue(
              res.properties.ircPurpose
                ? res.properties.ircPurpose
                : res.properties["Purpose (Other)"]
                ? res.properties["Purpose (Other)"]
                : "Other"
            ),
            dealStatus: "priority",
            action: "insert",
            dealIrcId: res.id,
          };
          await httpPost(`/DealChecklist/Insert`, payloadChecklist);
        } else {
          props.setToast({
            type: toastType.CRM_FORM_UPDATE,
            severity: toastSeverity.SUCCESS,
            message: props.responseMessage,
            status: res.status,
          });
        }

        setSteps([]);
        setOpen(false);
      }
      if ("isEA" in res) {
        props.setToast({
          type: toastType.EDGE_ADDED,
          severity: toastSeverity.SUCCESS,
          message: toastType.EDGE_ADDED,
          status: true,
        });
        // Due to the page refresh, the toast message is also removed. Therefore, using a timeout here.
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return;
      }
      if (isFormSql === "SQL form" && res?.result?.data?.[0]?.errorMessage) {
        let msg = res?.result?.data?.[0]?.errorMessage;
        res = {
          response: {
            data: {
              Message: msg,
            },
          },
        };
        setIsError(res);
      } else {
        if ("message" in res) {
          setIsError(res);
          setIsLoading(false);
        } else {
          setIsError(false);
          handleCrmEngineSuccess(res);
        }
      }
    }
    setIsLoading(false);
  };
  const handleFileShare = async () => {
    setIsLoading(true);
    let newFormData = { ...formData };
    const recordId = uuid();
    for (let i in newFormData) {
      if (
        newFormData[i] &&
        newFormData[i].fieldType &&
        newFormData[i].fieldType == fieldType.DOCUMENT
      ) {
        filelist.push(newFormData[i]);
        newFormData[i] = undefined;
      }
    }

    fileName = recordId;
    const userEmail = user && user.profile && user.profile.email;
    const userName = `${user && user.profile && user.profile.given_name} ${
      user && user.profile && user.profile.family_name
    }`;
    if (filelist && filelist.length > 0) {
      await handleFileUploadFileShare(
        user,
        filelist,
        fileName,
        "",
        "",
        false,
        portfolio,
        borrower,
        userEmail,
        userName,
        fieldName,
        newFormData.proposalId ? newFormData.proposalId : ""
      );
    }
    setIsError(false);
    props.setToast({
      type: toastType.EDGE_ADDED,
      severity: toastSeverity.SUCCESS,
      message: snackBarContent,
      status: true,
    });
    setTimeout(() => {
      props.getCurrentPage({ pageUrl, crmId });
    }, 2000);
    setIsLoading(false);
  };
  const handleCrmEngineSuccess = (obj) => {
    if ("id" in obj) {
      setShowFormWarning(false);
      props.fromAddEntity ? setFormSavedResponse(obj) : "";
      if (props.showPopup === false) {
        setConfirmationPopup(false);
      } else {
        setConfirmationPopup(true);
      }
    }
    setIsLoading(false);
    if (!props.refresh && filelist.length == 0) {
      if (props.IRCSlotCardType === "IRCSlotCardType") {
        props.IRCSlotPayloadCallback();
      } else {
        if (props.callBack) {
          props.callBack({ ...formData, ...editFormData });
        } else {
          props.getCurrentPage({ pageUrl, crmId });
        }
      }
    }
  };
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleFormEdit = () => {
    let newPageUrl =
      props &&
      props.chartConfigurations &&
      props.chartConfigurations.action &&
      props.chartConfigurations.action.pageUrl
        ? props.chartConfigurations.action.pageUrl
        : pageUrl;
    setIsLoading(true);
    let newFormData = { ...editFormData };
    for (const key in newFormData) {
      if (newFormData.hasOwnProperty(key)) {
        if (isJsonString(newFormData[key])) {
          if (JSON.stringify(newFormData[key]).includes("title")) {
            delete newFormData[key];
          }
        }
      }
    }
    try {
      if (
        props &&
        props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.description == "Portfolio Investments"
      ) {
        newFormData.Date = manageEntityHelper.getEcpochDateOfOptionSetMonth(
          newFormData.Date
        );
      }
    } catch (e) {
      console.log(e);
    }
    let filterFormData = {};
    if (pageFilters && hasPageFilter(props.currentPage)) {
      // eslint-disable-next-line react/prop-types
      pageFilters.forEach((pageFilter) => {
        filterFormData[pageFilter.filterName] = pageFilter.value;
      });
    }
    isFormSql = isFormSql == "SQL form" ? true : false;
    if (!isFormSql) setAutoPopulateData(...autoPopulateData, newFormData);
    const apiResponse = handleUpdate(
      newFormData,
      props,
      newPageUrl,
      crmId,
      fileUploadConfig,
      user,
      labelName,
      submitToStoredProc,
      submitToStoredProcSource,
      filterFormData,
      isFormSql
    );
    apiResponse.then((res) => {
      let msg = "";
      if (isFormSql && res?.result?.data?.[0]?.errorMessage) {
        msg = res?.result?.data?.[0]?.errorMessage;
        res = {
          response: {
            data: {
              Message: msg,
            },
          },
        };
        setIsError(res);
        setIsLoading(false);
        return;
      }
      if (res && res.status) {
        setShowFormWarning(false);
        setFormSavedResponse(apiResponse.result);
        try {
          if (props.callBack) {
            props.callBack(newFormData);
          } else {
            props.setToast({
              type: toastType.CRM_FORM_UPDATE,
              severity: toastSeverity.SUCCESS,
              message: message.CRM_FORM_UPDATE,
              status: res.status,
            });
          }
        } catch (error) {
          props.setToast({
            type: toastType.CRM_FORM_UPDATE,
            severity: toastSeverity.SUCCESS,
            message: message.CRM_FORM_UPDATE,
            status: res.status,
          });
          // Please log this error in case of debugging
        }
      } else {
        setIsError(res.result);
        props.setToast({
          type: toastType.CRM_FORM_UPDATE,
          severity: toastSeverity.ERROR,
          message: message.CRM_FORM_UPDATE_ERROR,
          status: true,
        });
      }
      setOpen(false);
      setSteps([]);
      setIsLoading(false);
      dispatch(clearPreviousEntity());
      props.resetManagePage();
    });
  };
  const handleActiveStep = (actStep) => {
    setActiveStep(actStep);
    setIsError(false);
  };
  const handleFormData = (formData, fieldId) => {
    let visibleSteps = handleShowHide(allSteps, fieldId, formData);
    setSteps(visibleSteps);
    // TO-DO ircPurpose field issue ,this line has to be removed
    // if (formData && !formData.deal && formData.ircPurpose) {
    //   formData.ircPurpose = "";
    // }
    let tempFormData = { ...formData };
    try {
      if (tempFormData) {
        let { content } = allSteps[activeStep];
        let visibleContent = visibleSteps[activeStep].content;
        if (content && visibleContent) {
          content.forEach((contentItem) => {
            if (
              !visibleContent.find(
                (visibleContentItem) => visibleContentItem.id === contentItem.id
              ) &&
              tempFormData[contentItem.id] &&
              contentItem.show &&
              contentItem.visibility != false
            ) {
              delete tempFormData[contentItem.id];
            }
          });
        }
      }
    } catch (e) {
      // Log this line incase of debugging console.log("Error",e)
    }
    setFormData(tempFormData);
    return tempFormData;
  };
  const handelShowhide = (formDataLocal, fieldId) => {
    let visibleSteps = handleShowHide(allSteps, fieldId);
    setSteps(visibleSteps);
    let tempFormData = { ...formData };
    try {
      if (formData) {
        let { content } = allSteps[activeStep];
        let visibleContent = visibleSteps[activeStep].content;
        let changeDataFlag = false;
        //TODO for future Case
        // for (let key in tempFormData) {
        //   if (tempFormData[key] === "") {
        //     delete tempFormData[key];
        //   }
        // }
        if (content) {
          content.forEach((contentItem) => {
            if (
              !visibleContent.find(
                (visibleContentItem) => visibleContentItem.id === contentItem.id
              ) &&
              tempFormData[contentItem.id] &&
              contentItem.show &&
              contentItem.visibility != false
            ) {
              delete tempFormData[contentItem.id];
              changeDataFlag = true;
            }
          });
        }
        if (changeDataFlag) {
          setFormData(tempFormData);
        }
      }
    } catch (e) {
      // Log this line incase of debugging console.log("Error",e)
    }
  };
  const handleEditFormData = (editFormData) => {
    // TO-DO ircPurpose field issue ,this line has to be removed
    // if (editFormData && !editFormData.deal && formData.ircPurpose) {
    //   editFormData.ircPurpose = "";
    // }
    setEditFormData(editFormData);
  };
  const handleShowHide = (allSteps, fieldId = undefined, formDataCopy = {}) => {
    let filterFormData = {};
    if (pageFilters && hasPageFilter(props.currentPage)) {
      // eslint-disable-next-line react/prop-types
      pageFilters.forEach((pageFilter) => {
        filterFormData[pageFilter.filterName] = pageFilter.value;
      });
    }
    formDataCopy = { ...formDataCopy, ...formData };
    let visibleSteps = allSteps.filter((step) => {
      if (
        !step.show ||
        (step.show &&
          showField(step.show, {
            ...formDataCopy,
            filter: { ...filterFormData },
          }))
      ) {
        return step;
      }
    });
    let visibleStepsNfields = visibleSteps.map((step) => {
      let visibleFields = step.content.filter((field) => {
        if (fieldId && field.id == fieldId) {
          field.focus = true;
        } else {
          field.focus = false;
        }
        if (
          !field.show ||
          (field.show &&
            showField(field.show, {
              ...formDataCopy,
              filter: { ...filterFormData },
            }))
        ) {
          return field;
        }
      });
      let newStep = {
        label: step.label,
        content: visibleFields,
      };

      return newStep;
    });
    return visibleStepsNfields;
  };
  return (
    <>
      <A_SimpleDialog
        style={{ margin: "auto", width: 300 }}
        formResize={props.formResize}
        open={open}
        IRCdate={props.IRCdate ? props.IRCdate : ""}
        title={
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.template
            ? props.managePage.data.template.name
            : ""
        }
        maxWidth="sm"
        onClose={handleClose}
        disableEscapeKeyDown={true}
        dialogContent={
          <React.Fragment>
            {props.managePage && !props.managePage.openManagePageForm ? (
              <div className={classes.loader}>
                <A_CircularIndeternment />
              </div>
            ) : (
              steps.length > 0 && (
                <ErrorBoundary>
                  <A_HorizontalStepper
                    getViewData={props.getViewData}
                    extraParams={props.extraParams}
                    fileUploadConfig={(data) => {
                      setFileUploadConfig(data);
                    }}
                    crmData={props.crmData}
                    isEditable={props.isEditable}
                    onloading={handelShowhide}
                    showPopup={props.showPopup}
                    steps={steps}
                    fields={fields}
                    optionSets={optionSets}
                    onFinish={
                      props.isFormUploadFileShare
                        ? handleFileShare
                        : props.isEditable
                        ? handleFormEdit
                        : handleFormSaved
                    }
                    closeFormWarning={showFormWarning}
                    onHandleWarningOkButton={handleWarningOkButton}
                    onHandleWarningCloseButton={handleWarningCloseButton}
                    onSave={
                      props.isFormUploadFileShare
                        ? handleFileShare
                        : props.isEditable
                        ? handleFormEdit
                        : handleFormSaved
                    }
                    isLoading={isLoading}
                    formSavedResponse={formSavedResponse}
                    confirmationPopup={confirmationPopup}
                    isError={isError}
                    activeStep={activeStep}
                    onActiveStepChanged={handleActiveStep}
                    onFormDataChange={handleFormData}
                    onFormEdit={handleEditFormData}
                    formData={formData}
                    entity={props.entity}
                    fromAddEntity={props.fromAddEntity}
                    editFormData={editFormData}
                    customCalculation={customCalculation}
                    isFormSql={isFormSql === "SQL form"}
                  />
                </ErrorBoundary>
              )
            )}
          </React.Fragment>
        }
      ></A_SimpleDialog>
    </>
  );
};

M_ManageEntityForm.defaultProps = {
  title: "Add",
  label: [{ textFieldLabel: " Date" }, { textFieldLabel: " time" }],
  color: "primary",
};

function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      managePageActions,
      currentPageActions,
      toastActions,
      loggedInUserIdActions
    ),
    dispatch
  );
}
M_ManageEntityForm.propTypes = {
  resetManagePage: PropTypes.func,
  open: PropTypes.bool,
  crmData: PropTypes.object,
  entity: PropTypes.object,
  editChart: PropTypes.bool,
  managePage: PropTypes.object,
  getPage: PropTypes.func,
  templateId: PropTypes.any,
  onClose: PropTypes.func,
  isIRCSlot: PropTypes.any,
  IRCSlotDate: PropTypes.any,
  IRCTime: PropTypes.any,
  showPopup: PropTypes.bool,
  IRCRecordId: PropTypes.any,
  addEdge: PropTypes.any,
  IRCdate: PropTypes.any,
  isEditable: PropTypes.bool,
  resetManage: PropTypes.bool,
  refresh: PropTypes.any,
  setToast: PropTypes.func,
  currentPage: PropTypes.any,
  edgeId: PropTypes.any,
  IRCSlotPayload: PropTypes.any,
  getIrcSlot: PropTypes.any,
  IRCSlotCardType: PropTypes.any,
  IRCSlotPayloadCallback: PropTypes.func,
  fromAddEntity: PropTypes.bool,
  loggedIn: PropTypes.any,
  chartConfigurations: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_ManageEntityForm);

/* eslint-disable */
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import PersonIcon from "@material-ui/icons/Person";
import Slide from "@material-ui/core/Slide/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import userManager from "../../../../../Utils/Oidc/userManager";
import M_UserAvatar from "./M_UserAvatar";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import A_Paper from "../../../Atoms/Surfaces/Papers/A_Paper";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getCurrentUser } from "../../../../../Utils/Helpers/common";
import TuneIcon from "@material-ui/icons/Tune";
import { withTranslation } from "react-i18next";
import Email from "@material-ui/icons/MailOutline";
import Exit from "@material-ui/icons/ExitToApp";
import UserPrefrences from "./UserPrefrences";
import EaAccessUserPref from "./EaAccessUserPref";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import A_Button from "../../../../../components/Factory/Atoms/Inputs/Buttons/A_Button";
import {
  excludedClientsForProfileSetting,
  isClientAuthorised,
} from "./M_NavBarhelper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import { flushBreadCrumps } from "../PageHeaders/TemplatePageTitle/M_BreadCrumbs.helper";
import PeopleIcon from "@material-ui/icons/People";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { resetState } from "../../../../../store/actions/resetStateActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      marginRight: "-15px",
    },
    //marginRight:'-15px'
  },
  person: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      textAlign: "center",
      fontSize: "0%",
    },
    whiteSpace: "nowrap",
    color: "white",
  },

  hideProfileSetting: {
    display: "none",
  },

  nowrap: {
    whiteSpace: "nowrap",
  },
  InfoIcon: { fontSize: "14px", color: "gray" },
}));

const M_UserArea = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = props;
  const [logoImage, setLogoImage] = useState("");
  let { crmContactUserId, isMinervaUser } = getCRMContactInfo();
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  const handleUserMenu = (event) => {
    if (!toggle) setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };

  let loggedInUser = `${user.profile.given_name} ${user.profile.family_name}`;

  const getImage = (userID) => {
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",
      headers: {
        filename: "profile_" + userID,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setLogoImage(imageAsUrl);
      });
  };
  useEffect(() => {
    if (userid && userid !== "" && userid !== null) {
      getImage(userid);
    }
  }, []);

  const handleClose = (clickedMenu) => {
    setAnchorEl(null);
    switch (clickedMenu) {
      case "logout":
        handleLogout();
        break;
      case "profile_settings":
        handleServerApp_Redirect("profile_settings");
        break;
      case "change_password":
        handleServerApp_Redirect("change_password");
        break;
      default:
        setAnchorEl(null);
    }
  };

  // This function is used to handle the logout process.
  const handleLogout = async () => {
    let data = {
      activityType: "Logout",
      message: "Logged out from system",
    };
    // Send a POST request to write a log entry in the database.
    // It's advisable to use a more descriptive URL or endpoint for your API.
    let res = await httpPost(
      `/Common/WriteDBLog?activityType=logout&message=Logged out from system`,
      data
    );
    // Remove the user's identity using userManager.
    userManager.removeUser().catch((error) => {
      console.error("Error removing user:", error);
    });
    // Sign the user out and redirect to the sign-in page.
    userManager.signoutRedirect().catch((error) => {
      console.error("Error signing out and redirecting:", error);
    });
    // First delete the redux store
    dispatch(resetState());
    localStorage.clear();
    sessionStorage.clear();
    // Define data to be sent in the request.
  };
  const handleServerApp_Redirect = (redirectTo) => {
    if (redirectTo === "change_password") {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/ForgetPassword?username=${btoa(user.profile.name)}`;
    } else {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/Manage?username=${btoa(user.profile.name)}`;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <A_Button
          label={loggedInUser ? loggedInUser : ""}
          variant="text"
          startIcon={<PersonIcon className={classes.personicon} />}
          onClick={(e) => handleUserMenu(e)}
          className={classes.person}
        ></A_Button>
      </div>
      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <div>
            <ClickAwayListener onClickAway={(e) => handleUserMenu(e)}>
              <div>
                <A_Paper
                  width={30}
                  // temp fix
                  // height={36}
                  height={"auto"}
                  content={
                    <ProfileMenu
                      handleClose={(e) => handleClose(e)}
                      {...props}
                      logoImage={logoImage}
                      setToggle={setToggle}
                    />
                  }
                  size="large"
                />
              </div>
            </ClickAwayListener>
          </div>
        </Slide>
      </Popper>
    </React.Fragment>
  );
};
const ProfileMenu = (props) => {
  //handle profile-menu click event.
  let history = useHistory();
  const classes = useStyles();
  const [radio, setRadio] = useState(false);
  const [openEaAcessDialog, setOpenEaAcessDialog] = useState(false);
  const [currentuser, setCurrentuser] = useState("");
  const [showUser, setShowUser] = useState(false);
  const [value, setValue] = useState("Select");
  let { displayName, userDesignation } = getCurrentUser();
  let loggedInUserDetail = JSON.parse(localStorage.getItem("contact_crm"));
  let { crmContactUserId, isMinervaUser, isEA } = getCRMContactInfo();
  const Prefrences = isMinervaUser ? true : false;
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : loggedInUserDetail && loggedInUserDetail.id
      ? loggedInUserDetail.id
      : crmContactUserId
  );
  useEffect(() => {
    setCurrentuser(userid ? userid : "");
    getPrefrence();
  }, [userid]);

  async function getPrefrence() {
    setShowUser(Prefrences ? true : false);
  }
  const { t: translate } = props;
  const handleMenuClick = (clickedMenu) => {
    props.handleClose(clickedMenu);
  };
  const [showProfileSetting, setShowProfileSetting] = useState(
    isClientAuthorised(
      window.location.hostname,
      excludedClientsForProfileSetting
    )
  );

  function mailBox() {
    window.location = `mailto:help@htb.co.uk`;
  }

  async function handleClick() {
    // let res = await httpPost(
    //   `/CRMData/getViewTemplates?pageUrl=contact&crmId=${userid}`
    // );
    // setValue(res && res.userPreference ? res.userPreference : "Select");
    setRadio(true);
    props.setToggle(true);
  }
  const handleClose = () => {
    setRadio(false);
    props.setToggle(false);
  };
  async function openEAcessDialog() {
    setOpenEaAcessDialog(true);
    props.setToggle(true);
  }
  const closeEAcessDialog = () => {
    setOpenEaAcessDialog(false);
    props.setToggle(false);
  };
  const handleClickToRedirect = () => {
    if (currentuser == "") {
      e.preventDefault();
    } else {
      flushBreadCrumps();
      location.assign(`/contact/${currentuser}`);
    }
  };
  const titleWithHTML = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        Meeting Preferences{"    "}
        <i className={classes.InfoIcon}>(EA ACCESS)&nbsp;</i>
      </span>
      <Tooltip title="Only an assistant can see or select an account.">
        <InfoIcon
          style={{ margin: "5px 5px 0px 0px", cursor: "pointer" }}
          fontSize="small"
          className={classes.InfoIcon}
        />
      </Tooltip>
    </div>
  );
  return (
    <React.Fragment>
      <List component="nav" aria-label="user area">
        {/* <ListItemLink
           href={`${"/contacts/123"}`}
           onClick={props.handleClose}
           tooltip="View your profile"
         > */}
        <ListItem
          onClick={() => {
            props.handleClose();
          }}
        >
          <ListItemIcon>
            <M_UserAvatar logoImage={props.logoImage} />
          </ListItemIcon>
          <ListItem
            component={currentuser ? "a" : ""}
            style={{
              color: "black",
              cursor: currentuser ? "pointer" : "not-allowed",
            }}
            onClick={handleClickToRedirect}
            disabled={currentuser ? false : true}
          >
            <ListItemText primary={displayName} secondary={userDesignation} />
          </ListItem>
        </ListItem>
        {/* </ListItemLink> */}
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          <A_Divider />
          {/* Commenting this code for now Because This code used in future */}
          {/* <ListItem button onClick={() => handleMenuClick("profile_settings")}>
            <Settings style={{ marginRight: "10px" }} />
            <ListItemText primary={translate("ProfileSettings")} />
          </ListItem> */}
        </div>
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          {/* <A_Divider /> */}
          {showUser === true ? (
            <ListItem button onClick={() => handleClick()}>
              <TuneIcon style={{ marginRight: "10px" }} />
              <ListItemText primary={translate("Meeting Preferences")} />
            </ListItem>
          ) : (
            ""
          )}
        </div>
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          {isEA ? (
            <ListItem button onClick={() => openEAcessDialog()}>
              <PeopleIcon style={{ marginRight: "10px" }} />
              <ListItemText primary={translate("EA Meeting Preferences")} />
            </ListItem>
          ) : (
            ""
          )}
        </div>
        <A_SimpleDialog
          open={radio}
          title="Meeting Preferences"
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          dialogContent={
            <UserPrefrences
              values={value}
              LogedUserId={currentuser}
              close={handleClose}
            />
          }
        />
        <A_SimpleDialog
          open={openEaAcessDialog}
          title={titleWithHTML}
          fullWidth={true}
          maxWidth={"md"}
          onClose={closeEAcessDialog}
          dialogContent={
            <EaAccessUserPref
              values={value}
              LogedUserId={currentuser}
              close={closeEAcessDialog}
            />
          }
        />
        {/*
         <ListItemLink href="/app_settings/123" onClick={props.handleClose}>
           <ListItemText primary={translate("ApplicationSettings")} />
 
         </ListItemLink>
         <A_Divider />
         <ListItem button onClick={() => handleMenuClick("change_password")}>
           <ListItemText primary={translate("ChangePassword")} />
         </ListItem> */}

        <A_Divider />

        <ListItem
          button
          onClick={() => {
            mailBox("Contact Support");
          }}
        >
          <Email style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Contact Support")} />
        </ListItem>
        <A_Divider />
        <ListItem button onClick={() => handleMenuClick("logout")}>
          <Exit style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Logout")} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
ProfileMenu.propTypes = {
  t: PropTypes.any,
  handleClose: PropTypes.func,
  logoImage: PropTypes.string,
};
M_UserArea.propTypes = {
  callback: PropTypes.func,
  layouts: PropTypes.array,
  currentPageId: PropTypes.string,
  user: PropTypes.object,
};
export default withTranslation()(M_UserArea);

import React from "react";
import LoadingMessage from "./LoadingMessage/LoadingMessage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../Utils/Oidc/userManager";
import { useHistory } from "react-router-dom";
import * as loggedInUserIdActions from "../../../store/actions/loggedInUserIdActions";
import PropTypes from "prop-types";
//TODO:For the future use Case
// import RedirectingMessage from "../RedirectingMessage/RedirectingMessage";
import "../RedirectingMessage/RedirectingMessage.css";
import { flushBreadCrumps } from "../../Factory/Molecules/Common/PageHeaders/TemplatePageTitle/M_BreadCrumbs.helper";
import {
  getRecordByProperty,
  validSubdomainUser,
  getColourScheme,
  getDefaultRedirectURLForUser,
  getGlobalSearchPermission,
} from "./apiService";
import { setShowSeach } from "../../../store/actions/globalSearchAction";

const CallbackPage = (props) => {
  const history = useHistory();
  React.useEffect(() => {
    flushBreadCrumps();
  });

  const getTheme = async (user) => {
    // Dispatch LoggedIn User Data
    await props.getLoggedInUser();

    // Dispatch Show Search
    const searchShowRes = await getGlobalSearchPermission(user);
    if (!searchShowRes.message) {
      props.setShowSeach(searchShowRes);
    } else {
      props.setShowSeach(false);
    }
    let email =
      props.state &&
      props.state.oidc &&
      props.state.oidc.user &&
      props.state.oidc.user.profile &&
      props.state.oidc.user.profile.email;

    if (email) {
      let apiPayload = {
        label: "contact",
        properties: {
          email: email,
        },
      };
      // Calling getRecordByProperty API
      getRecordByProperty(apiPayload, email);
    }
    await getColourScheme();

    localStorage.removeItem("manageMenus");
    // Calling Valid validsubdomainuser API
    const validSubdomainUserResponse = await validSubdomainUser();

    //user validation for subdomain
    let redirectUrl = null;
    if (validSubdomainUserResponse) 
    {
        //user validation for get user landing url
        redirectUrl = await getDefaultRedirectURLForUser();
      
    } else {
      redirectUrl = "/securelogout";
    }

    history.push(redirectUrl ? redirectUrl : "/dashboard");
    localStorage.setItem(
      "UserDefaultURL",
      redirectUrl ? redirectUrl : "/dashboard"
    );
  };

  const successCallback = (user) => {
    getTheme(user);
  };

  const errorCallback = () => {
    history.push("/");
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <div>
        <LoadingMessage />
      </div>
    </CallbackComponent>
  );
};
function mapStateToProps(state) {
  return { state, loggedIn: state.getLoggedInUserReducer };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, loggedInUserIdActions, { setShowSeach }),
    dispatch
  );
}
CallbackPage.propTypes = {
  getLoggedInUser: PropTypes.func,
  loggedinUser: PropTypes.func,
  loggedIn: PropTypes.array,
  currentPageId: PropTypes.string,
  content: PropTypes.any,
  state: PropTypes.any,
  setShowSeach: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);

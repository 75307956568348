import React, { lazy, Suspense, useState } from "react";
import PropTypes from "prop-types";
import PipeLineFilter from "../../../Organisms/Common/Pipeline/PipeLineFilter.jsx";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary.jsx";
import EsgHeader from "../Esg/EsgHeader.jsx";
import EsgResponsePage from "../Esg/EsgResponse/EsgResponsePage.jsx";
import { articleTypes, contentTypes, externalTypes } from "./layout.Helper.js";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button.jsx";
import { CardContext } from "./Card_Context.jsx";
import LoaderSkeleton from "../../../Molecules/Common/LoaderSkeleton.jsx";
import showContentConditional from "./showContentConditional.jsx";
const ExcelTemplate = lazy(() => import("../ExcelTemplate/ExcelTemplate.jsx"));
const ReportEditor = lazy(() =>
  import("../ReportEditor/MainPage/ReportEditor.jsx")
);
const A_Card = lazy(() => import("../../../Atoms/Surfaces/Cards/A_Card.jsx"));
const O_Pipeline = lazy(() =>
  import("../../../Organisms/Common/Pipeline/O_Pipeline.jsx")
);
const KpiCallBox = lazy(() =>
  import("../../../Organisms/Common/KpiCallBox/KpiCallBox.jsx")
);
const M_DealStatusBar = lazy(() =>
  import("../../../Molecules/Common/StatusBar/M_StatusBar.jsx")
);
const M_CustomCards = lazy(() => import("./CustomCards/M_CustomCards.jsx"));
const O_ConsentRequest = lazy(() =>
  import("../../../Organisms/Common/ConsentRequest/O_ConsentRequest.jsx")
);
const KpiSqlBox = lazy(() =>
  import("../../../Organisms/Common/KpiCallBox/KpiSqlBox.jsx")
);
const GetArticle = ({
  item,
  device,
  index,
  isReadOnly = false,
  parentThis,
  templateSecurity
}) => {
  const [context, setContext] = useState({});
  const alterGlobalContext = (contextValues) => {
    const updatedContext = { ...context, ...contextValues };
    setContext(updatedContext);
    console.log("current context is:", updatedContext);
  };
  const removeContextValue = (keyToRemove) => {
    const tempContext = { ...context };
    delete tempContext[keyToRemove];
    setContext(tempContext);
  };

  const {
    title,
    toolbar,
    showPic,
    chartFilter,
    addFiles,
    boldTitle,
    show = true,
    syncSlot = false,
  } = item.header;
  const { type, content } = item.content;

  if (
    item.content &&
    item.content.customCardName &&
    item.content.customCardName === "meetingSync"
  ) {
    if (
      parentThis.props.userid !== parentThis.props.params.crmId ||
      !parentThis.props.isMinervaUser
    ) {
      return null;
    }
  }
  if (
    item.content &&
    item.content.customCardName &&
    item.content.customCardName === "meetingSyncEA"
  ) {
    if (
      parentThis.props.userid !== parentThis.props.params.crmId ||
      !parentThis.props.isEA
    ) {
      return null;
    }
  }
  if (
    type === contentTypes.APPROVE_AND_REJECT &&
    !parentThis.state.shouldShowApproveRejectWindow
  ) {
    return null;
  }
  if (
    type === contentTypes.PORTFOLIOAPPROVAL &&
    !parentThis.state.shouldShowApprovePortfolio
  ) {
    return null;
  }
  if (
    type === contentTypes.SUBMISSION_REVIEW &&
    !parentThis.state.shouldShowsubmissionWindow
  ) {
    return null;
  }

  const renderCardStyle = (item, device) => {
    const resultObject = item.position[device];
    if (
      device &&
      (device === "sm" || device === "xs") &&
      resultObject &&
      resultObject["grid-column"]
    ) {
      return { "grid-column": "span 12" };
    }
    return resultObject;
  };

  const handleFooter = (footer) => {
    if (footer.type === externalTypes.BUTTON) {
      return footer.content.map((button, index) => (
        <A_Button {...button} key={index} />
      ));
    } else {
      return footer.content;
    }
  };

  const handleComponentRendering = () => {
    switch (type.toLowerCase()) {
      case articleTypes.DEALSTATUSBAR.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <M_DealStatusBar content={content} />
          </Suspense>
        );
      case articleTypes.REPORT_EDITOR.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <ReportEditor />
          </Suspense>
        );
      case articleTypes.ESG.toLowerCase():
        return <EsgHeader />;
      case articleTypes.ESGRESPONSE.toLowerCase():
        return <EsgResponsePage />;
      case articleTypes.EXCEL_EDITOR.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <ExcelTemplate
              isExcelTemplateDownload={parentThis.props.isExcelTemplateDownload}
              handleClosePopup={parentThis.props.handleClosePopup}
            />
          </Suspense>
        );
      case articleTypes.KPI_CALL_BOX.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <KpiCallBox item={item} />
          </Suspense>
        );
      case articleTypes.KPI_SQL_BOX.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <KpiSqlBox item={item} />
          </Suspense>
        );
      case articleTypes.PIPE_LINE_FILTER.toLowerCase():
        return (
          <PipeLineFilter
            item={item}
            pipeLineDealsControll={parentThis.pipeLineDealsControll}
          />
        );
      case articleTypes.DEALS_PIPE_LINE.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <O_Pipeline
              item={item}
              dealData={parentThis.state.pipelineData}
              imageData={parentThis.state.pipelineImages}
            />
          </Suspense>
        );
      case articleTypes.CONSENTREQUEST.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <O_ConsentRequest
              title={title ? title : ""}
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
            />
          </Suspense>
        );
      case articleTypes.CUSTOMCARD.toLowerCase():
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <M_CustomCards
              content={content}
              item={item}
              stateObj={parentThis.state}
            />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<LoaderSkeleton />}>
            <A_Card
              currentPage={
                parentThis.props &&
                parentThis.props.currentPage &&
                parentThis.props.currentPage.data
              }
              elevation={2}
              width={50}
              header={{
                title: title ? title : "",
                boldTitle: boldTitle ? boldTitle : "",
                chartFilter: chartFilter ? chartFilter : "",
                addFiles: addFiles ? addFiles : "",
                showPic: showPic ? showPic : false,
                show,
                isReadOnly,
                secondaryMenu: false,
                optionAdd: parentThis.state.optionOnAdd,
                addParameterOption:
                  item &&
                  item.header &&
                  item.header.toolbar &&
                  item.header.toolbar.optionOnAdd
                    ? item.header.toolbar.optionOnAdd.parameters
                    : {},
                callBack: parentThis.handleClickOptionAdd,
                add:
                  toolbar && toolbar.add && toolbar.add.show
                    ? toolbar.add.show
                      ? isReadOnly !== true
                      : false
                    : false,
                optionOnAdd:
                  toolbar && toolbar.optionOnAdd && toolbar.optionOnAdd.show
                    ? toolbar.optionOnAdd.show
                    : false,
                edit:
                  toolbar && toolbar.edit && toolbar.edit.show
                    ? toolbar.edit.show
                      ? isReadOnly !== true
                      : false
                    : false,
                export:
                  toolbar && toolbar.export && toolbar.export.show
                    ? toolbar.export.show
                    : false,
                syncSlot,
              }}
              action={
                item.footer && item.footer.content
                  ? handleFooter(item.footer)
                  : ""
              }
              ApproveButtons={
                item.footer && item.footer.approvalButtons === true
                  ? item.footer.approvalButtons
                  : ""
              }
              templateSecurity={templateSecurity}
              content={
                <ErrorBoundary>
                  {content
                    ? showContentConditional(
                        type,
                        content,
                        item,
                        chartFilter,
                        parentThis
                      )
                    : item.fallbackMsg}
                </ErrorBoundary>
              }
              handleZoom={() => parentThis.handleZoom(toolbar, item)}
              handleEdit={() => parentThis.handleEdit(toolbar, item, content)}
              handleAdd={() => parentThis.handleAdd(toolbar, item, content)}
              handleOptionOnAdd={() =>
                parentThis.handleOptionOnAdd(toolbar, item, content)
              }
              handleDeleteOnAdd={() =>
                parentThis.handleDeleteOnAdd(toolbar, item, content)
              }
              handleExport={() =>
                parentThis.handleExport(toolbar, item, content)
              }
              handleClickOptionAdd={parentThis.handleClickOptionAdd}
            />
          </Suspense>
        );
    }
  };

  return (
    <article
      key={index}
      style={
        item && item.position
          ? renderCardStyle(item, device)
          : { gridColumn: "span 3" }
      }
    >
      <ErrorBoundary>
        <CardContext.Provider
          value={{
            cardContentConfig: content,
            context,
            alterGlobalContext,
            removeContextValue,
          }}
        >
          {handleComponentRendering()}
        </CardContext.Provider>
      </ErrorBoundary>
    </article>
  );
};

GetArticle.propTypes = {
  item: PropTypes.object.isRequired,
  device: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  parentThis: PropTypes.object.isRequired,
  templateSecurity:PropTypes.object
};

export default GetArticle;
